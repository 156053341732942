.inputlabel {
  font-family: 'Poppins', sans-serif;
  font-size: 2.2vmin;
  font-weight: 400;
  margin-bottom: 4px;
  color: 'black';
}


.btn_register {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 2.5vmin;
  background-color: #FF9902;
}

.header {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 3vmin;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
  text-decoration: underline;
  color: black;
}

.mainheader {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 4vmin;
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

.custom-list {
  list-style: none;
  /* Remove default list styles */
  padding: 0;
  /* Remove default padding */

}

.custom-list li {
  background-image: url('../../assets/orange-check-mark.png');
  /* Replace with the path to your orange tick image */
  background-repeat: no-repeat;
  background-size: 30px 30px;
  /* Set the size of your tick image */
  padding-left: 6vh;
  /* Adjust the padding to make space for the image */
  margin-top: 5vmin;
  background-position: left;
  /* Adjust line height for better spacing */
}

.error-message {
  color: red;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 12px;
  margin-left: 10px;
}

.form-check {
  display: flex !important;
  align-items: center;
}

.form-check-label {
  position: relative;
  top: 0;
  left: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 8px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: white;
  font-size: 10px;
}

.form-check .form-check-input {
  min-height: 3vmin;
  width: 3vmin;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 12px;
  /* background-color:rgba(141, 171, 192, 1) !important; */
}

.normaltext {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 4vmin;
  color: white;
}

.boldtext {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 4vmin;
  color: white;
}

.shadowdiv {
  background-color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 5px 5px rgba(200, 192, 192, 0.2);
  border-radius: 10px !important;
  padding-right: 8vmin !important;
  padding-left: 8vmin !important;
  backdrop-filter: blur(15px);
}

.form-check-input {
  background-color: none !important;
}

.rownames {
  --bs-gutter-x: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.form-control {
  /* background-color: rgba(169, 216, 231, 0.7) !important; */
  background-color: rgba(154, 187, 211, 0.5) !important;
  min-height: 45px;
  border-radius: 5px;
  margin-bottom: 5px;
  color: white !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 1 rem !important;
  margin-bottom: 10px !important;
  border: none !important;
}

.form-control::placeholder {
  color: white !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 0.8rem;
}

.borderstyle {
  background-color: rgba(255, 255, 255, 0.741);
  content: "";
  height: 1.5px;
  left: 15%;
  bottom: 0;
  position: relative;
  width: 100px;
}

.btnsubmit {
  /* background-color:rgba(141, 171, 192, 1) !important; */
  border: 1px solid rgb(255, 255, 255);
  color: white;
  padding: 6px;
  padding-right: 5vmin !important;
  padding-left: 5vmin !important;
  border-radius: 10px;
  margin-bottom: 20px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 2min;
}

.varumtext {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  font-size: 4vmin;
}

.error-row {
  margin-top: -5px;
  margin-bottom: 5px;
  align-items: center;
}

.error-row1 {
  margin-top: -0px;
  margin-bottom: 5px;
  align-items: center;
}

.col-input {
  padding-right: calc(var(--bs-gutter-x)* 0.1) !important;
  padding-left: calc(var(--bs-gutter-x)* 0.1) !important;
}

.form-control {
  font-size: 16px;
  /* Set a consistent font size */
  width: 100%;
  /* Ensure the input field takes the full width of the column */
  box-sizing: border-box;
  /* Ensure padding and border are included in the element's total width and height */
}