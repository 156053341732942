.paragraph {
    margin-bottom: 0px !important;
}

.paragraph-container {
    height: 250px;
    /* Set the desired fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: hidden;
}

.adname {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    text-decoration: underline;
    color: orange;
    cursor: pointer;
}

.addate {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: black;
}

.adheader {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: gray;
    display: block;
}

.headertext {
    font-size: 30px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    color: black;
}

.btnsave {
    background-color: green;
    border: none;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
}

.btnIgnore {
    background-color: rgb(66, 66, 65);
    border: none;
    color: white;
    padding: 5px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
}

.publishbtninsta {
    background-color: rgb(241, 144, 7);
    border: none;
    color: white;
    padding: 5px 16px;
    border-radius: 5px;
    cursor: pointer;

    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
}

@media (max-width: 768px) {

    .btnsave,
    .publishbtninsta {
        margin-top: 10px;
        width: 100%;
    }

    .btnIgnore {
        width: 100%;
        margin-top: 10px;
    }

    .paragraph {
        text-align: center;
        padding: 10px;
    }
}

/* Base styles */
.adheader,
.addate,
.adname {
    display: block;
    /* ensures full width on small screens */
}

/* Responsive image settings */
.instaimg {
    width: 100%;
    height: auto;
    max-width: 250px;
    max-height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
    /* maximum width */
}

/* Flex adjustments for smaller screens */
@media (max-width: 1024px) {
    .flex-grow-1 {
        flex-direction: column;
        align-items: center;
    }

    .btnsave,
    .publishbtninsta {
        margin-top: 10px;
        width: 100%;
    }

    .btnIgnore {
        width: 100%;
        margin-top: 10px;
    }


    .paragraph {
        text-align: center;
        padding: 10px;
    }

}

/* Adjustments for very small screens */
@media (max-width: 480px) {

    .ml-3,
    .mb-4 {
        margin-left: 0;
        margin-bottom: 0;
    }

    .adheader,
    .addate,
    .adname {
        text-align: center;
    }

    .form-control {
        width: 90%;
        /* smaller width for text area */
        margin: auto;
        /* centering text area */
    }
}