.h4_textstyle {
  color: white;
  padding-right: 5px;
  padding-top: 10px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
  /* font-size: 36px; */
  font-weight: 600;
}

.h1_textstyle {
  color: white;
  padding-top: 15px;
  max-width: 100%;
  font-family: 'Poppins', sans-serif;
  /* font-size: 52px; */
  font-weight: 700;
}

.grid-item {
  position: relative;
  height: 50vh;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
}

.nameTxt {
  font-family: 'Poppins', sans-serif;
  font-size: 4vmin;
  font-weight: 400;
  text-align: left;
}

.locationTxt {
  font-family: 'Poppins', sans-serif;
  font-size: 3vmin;
  font-weight: 400;
  text-align: left;
}

.image-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  background-position: center
}

.details-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* transform: translate(-50%, -50%); */
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  /* Adjust the transparency as needed */
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
}



.addressText {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 35px;
}

.addressdetails {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 3vmin;
  font-weight: 500;
}

.btnLink {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 3vmin;
  font-weight: 500;
  margin-left: 10px
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(17 17 17 / 30%);
  top: 0;
  left: 0;
}

.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1600px;
  width: 90%;
}

@media (max-width: 767px) {
  .banner {
    min-height: 300px;
    display: flex;
    align-items: center;
  }

  .dnd_area-module-2 {
    padding-top: 190px;
    padding-right: 0px;
    padding-bottom: 100px;
    padding-left: 0px;
  }

  .h4_textstyle {
    font-size: 27px;
  }


  .h1_textstyle {
    font-size: 36px;
  }

}

.dnd_area-module-2 {
  padding-top: 263px;
  padding-right: 0px;
  padding-bottom: 224px;
  padding-left: 0px;
  position: relative;
}

.blurview {
  border-radius: 5px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  backdrop-filter: blur(2px);
  background-color: rgba(46, 36, 59, 0.192);
  width: 100%;
  display: flex;
  justify-content: center;

}

.banner {
  max-height: 550px;
  display: flex;
  align-items: center;
}



.headingtext {
  font-size: 45px;
  font-weight: 400;
  color: #000;
  font-family: 'Poppins', sans-serif;
  line-height: 1.37;
}

/* Adjust font size for small devices */
@media only screen and (max-width: 768px) {
  .headingtext {
    font-size: 32px;
    /* Adjust this value as needed */
  }
}









.filter-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  align-content: center;
  color: white;
}

.link {
  border: 1px solid rgba(255, 255, 255, 1.0);
  align-items: center;
  border-radius: 4px 4px 12px 4px;
  display: flex;
  height: 57px
}